<template>
  <div>
    <v-dialog v-model="dialog" width="850">
      <v-card flat class="pa-4">
        <v-card-title class="d-flex justify-right">
          <h2 class="">Gallery</h2>
          <v-spacer></v-spacer>
          <p class="caption grey--text mt-3">problemLayoutModal.vue</p>

          <v-btn small color="error" text @click="del_problem"> Delete </v-btn>
          <v-btn color="" @click="$emit('close', true)">Close</v-btn>
        </v-card-title>
        <v-card-text>
          <div>
            <v-row>
              <v-col
                v-for="photo in project_photos"
                :key="photo.id"
                class="d-flex child-flex"
                cols="6"
              >
                <v-img
                  :src="photo.picture_url"
                  lazy-src="@/assets/jmbcircle.png"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  @click="view_image(photo.picture_url)"
                >
                  <h3 class="white--text pa-2">
                    Title: {{ photo.picture_description }}
                  </h3>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </div>
          <h3 class="mt-4">Upload Picture:</h3>
          <div class="mt-2 d-flex mb-10">
            <input
              class="black--text"
              type="file"
              ref="photo_upload"
              name="photo_upload"
              @change="insert_image"
              accept="image/jpeg"
            />
            <v-text-field
              class="mt-n4 mr-2"
              label="Photo Description"
              v-model="picture_description"
            ></v-text-field>
            <v-btn
              small
              class=""
              color=""
              @click="upload_image"
              :loading="uploading"
            >
              Upload Picture</v-btn
            >
          </div>
          <h1 class="mb-3">
            <!-- <h2 class="success--text"> -->
            {{ problem.problem_description }}
          </h1>
          <div class="d-flex">
            <h2>Problem ID: {{ problem.id }}</h2>
            <h2 class="ml-10">
              Status:

              <span
                :class="
                  problem.status == 'Pending' ? 'orange--text' : 'green--text'
                "
                >{{ problem.status }}</span
              >
            </h2>
            <h2 class="ml-10">Technician: {{ problem.technician }}</h2>
            <h2 class="ml-10">
              Date Inserted:
              {{ date_inserted }}
            </h2>
          </div>
          <v-divider class="mt-4 mb-4"></v-divider>
          <div class="row">
            <div class="col">
              <h3>
                Functionals:
                <ol>
                  <li v-for="functional in functionals" :key="functional.id">
                    {{ functional }}
                  </li>
                </ol>
              </h3>
            </div>
            <div class="col">
              <h3>
                Accessories:
                <ol>
                  <li v-for="accessory in accessories" :key="accessory.id">
                    {{ accessory }}
                  </li>
                </ol>
              </h3>
            </div>
          </div>
        </v-card-text>

        <UpdateProblem
          v-if="updateModal"
          :item="problem"
          @update_problem="update_problem"
        />
      </v-card>

      <problem-picture-modal
        :picture_url="selected_picture_url"
        :picture_description="picture_description"
        v-if="enable_view_picture"
        @close="enable_view_picture = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import UpdateProblem from "./updateProblem.vue";
import ProblemPictureModal from "./problemPictureModal.vue";
export default {
  components: { UpdateProblem, ProblemPictureModal },
  props: ["problem", "unit"],
  data() {
    return {
      selected_picture_url: "",
      enable_view_picture: false,
      uploading: false,
      updateModal: true,
      dialog: true,
      picture: "",
      picture_url: "",
      picture_description: "",
    };
  },
  methods: {
    ...mapActions({
      delete_problem: "problem/delete_problem",
      add_project_photo: "problem/add_project_photo",
      get_project_photos: "problem/get_project_photos",
    }),
    update_problem(data) {
      this.problem = data;
    },
    view_image(image_url) {
      // console.log(image_url);
      this.selected_picture_url = image_url;
      this.enable_view_picture = true;
    },
    insert_image(e) {
      this.picture = e.target.files[0];
      this.picture_url = URL.createObjectURL(this.picture);
    },
    upload_image() {
      // check if empty
      if (this.picture_description == "")
        return alert("Please insert picture description");
      if (this.picture == "")
        return alert("Please insert image before uploading");
      this.uploading = true;
      const storage = getStorage();
      const storageRef = ref(
        storage,
        "TroubleshootingPhotos/" + this.picture_url
      );
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, this.picture).then(() => {
        getDownloadURL(storageRef).then((downloadURL) => {
          // console.log(downloadURL);
          let request = {
            picture_url: downloadURL,
            problem_id: this.problem.id,
            picture_description: this.picture_description,
          };
          this.add_project_photo(request).then(() => {
            alert("Successfully uploaded!");
            this.uploading = false;
          });
        });
      });
    },
    del_problem() {
      let x = window.confirm("Are you sure you want to delete this problem?");
      if (x) {
        this.delete_problem(this.problem.id)
          .then(() => {
            alert("Successfully deleted a problem! PLEASE UPDATE THE TABLE");
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    open_update_modal() {
      this.updateModal = true;
    },
  },
  mounted() {
    // console.log(this.problem);
    this.get_project_photos(this.problem.id);
  },
  computed: {
    accessories() {
      let accessories = JSON.parse(this.problem.accessories);
      if (!accessories) return;
      return accessories;
    },
    functionals() {
      let functionals = JSON.parse(this.problem.includes);
      if (!functionals) return;
      return functionals;
    },
    ...mapGetters({
      project_photos: "problem/project_photos",
    }),

    date_inserted() {
      var formattedDate = moment(this.problem.created_at).format(
        "MMMM D, YYYY"
      );
      return formattedDate;
      // return this.item.created_at;
    },
  },
};
</script>

<style lang="scss" scoped></style>
