<template>
  <div>
    <v-dialog v-model="dialog" width="700" persistent>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10"
              ><h2>{{ item.unit_brand }} - {{ item.unit_model }}</h2></v-col
            >
            <v-spacer></v-spacer>
            <v-col> </v-col>
            <v-col cols="1">
              <v-btn icon color="warning" @click="dialog = false">
                <v-icon>mdi-close</v-icon></v-btn
              ></v-col
            >
          </v-row>
        </v-card-title>
        <v-card-text>
          <p class="overline mt-3">Add Problem</p>
          <v-text-field
            label="Problem Description"
            prepend-icon="mdi-pen-plus"
            v-model="form.problem_description"
          ></v-text-field>

          <v-row>
            <v-col>
              <p>Functional Components</p>
              <div class="container d-flex mt-n6">
                <v-checkbox
                  v-model="form.includes"
                  label="All"
                  value="All"
                ></v-checkbox>
                <v-checkbox
                  class="ml-2"
                  v-model="form.includes"
                  label="Keyboard"
                  value="Keyboard"
                ></v-checkbox>
                <v-checkbox
                  class="ml-2"
                  v-model="form.includes"
                  label="Track Pad"
                  value="Track Pad"
                ></v-checkbox>
                <v-checkbox
                  class="ml-2"
                  v-model="form.includes"
                  label="Speaker"
                  value="Speaker"
                ></v-checkbox>
                <v-checkbox
                  class="ml-2"
                  v-model="form.includes"
                  label="Wifi"
                  value="Wifi"
                ></v-checkbox>
                <v-checkbox
                  class="ml-2"
                  v-model="form.includes"
                  label="N/a"
                  value="N/a"
                ></v-checkbox>
              </div>
              <p>With Accessories</p>
              <div class="container d-flex mt-n6">
                <v-checkbox
                  v-model="form.accessories"
                  label="All"
                  value="All"
                ></v-checkbox>
                <v-checkbox
                  class="ml-2"
                  v-model="form.accessories"
                  label="Charger"
                  value="Charger"
                ></v-checkbox>
                <v-checkbox
                  class="ml-2"
                  v-model="form.accessories"
                  label="Bag"
                  value="Bag"
                ></v-checkbox>
                <v-checkbox
                  class="ml-2"
                  v-model="form.accessories"
                  label="Cords/Cables"
                  value="Cords/Cables"
                ></v-checkbox>

                <v-checkbox
                  class="ml-2"
                  v-model="form.accessories"
                  label="N/a"
                  value="N/a"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
          <v-btn
            :loading="loading"
            color="primary"
            class=""
            @click="add_new_problem"
            >Add</v-btn
          >

          <!-- PROBLEM TABLE -->
          <div class="mt-5">
            <v-data-table :headers="problem_headers" :items="item.problems">
              <template v-slot:item.action="{ item }">
                <div class="d-flex">
                  <v-btn @click="show_problem(item)" color="primary" small icon
                    ><v-icon>mdi-list-box</v-icon></v-btn
                  >
                  <v-btn color="success" small icon @click="printJobOrder(item)"
                    ><v-icon>mdi-printer</v-icon></v-btn
                  >
                  <v-btn icon small color="red" @click="del(item)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </div>
              </template>
            </v-data-table>
            <problem-layout-modal
              :problem="selected_problem"
              :unit="selected_unit"
              v-if="show_problem_layout"
              @close="show_problem_layout = false"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="" @click="$emit('close')" text>Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">Problems/problemsModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      selected_unit: [],
      selected_problem: [],
      show_problem_layout: false,
      problem_dialog: false,
      loading: false,
      form: {
        customer_id: "",
        unit_id: "",
        problem_description: "",
        actions_performed: [],
        recommendations: [],
        other_remarks: [],
        accessories: [],
        includes: [],
        status: "Pending",
      },
      dialog: true,
      problem_headers: [
        { text: "", value: "action" },
        { text: "ID", value: "id" },
        { text: "Problem Description", value: "problem_description" },
        { text: "Technician", value: "technician" },
        { text: "Status", value: "status" },
        { text: "Created at", value: "created_at" },
        { text: "Last Update", value: "updated_at" },
      ],
      headers: [
        { text: "", value: "action", width: 100 },
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Problem Description",
          value: "problem_description",
          width: 200,
        },
        { text: "Actions Performed", value: "actions_performed", width: 300 },
        { text: "Recommendations", value: "recommendations", width: 300 },
        { text: "Other Remarks", value: "other_remarks", width: 300 },
        { text: "Status", value: "status" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      problems: "problem/problems",
      default_server_url: "auth/default_server_url",
    }),
  },
  methods: {
    ...mapActions({
      get_problems: "problem/get_problems",
      add_problem: "problem/add_problem",
      set_notification: "notification/set_notification",
      set_problem: "problem/set_problem",
      del_problem: "problem/delete_problem",
    }),
    printJobOrder(item) {
      // console.log(item);
      var data = item.problem_description;
      var unitId = item.unit_id;
      var url =
        this.default_server_url +
        "/print_job_order_slip/" +
        data +
        "/" +
        unitId;
      window.open(url);
    },
    show_problem(item) {
      this.selected_problem = item;
      this.selected_unit = this.item;
      this.show_problem_layout = true;
    },
    del(item) {
      this.loading = true;
      let x = window.confirm("Are you sure you want to delete this problem?");
      if (x) {
        // remove this problem from item.problems
        this.del_problem(item.id).then(() => {
          let filtered = this.item.problems.filter((i) => {
            return i.id != item.id;
          });
          this.item.problems = filtered;
          alert("Successfully deleted");
        });

        this.loading = false;
      }
    },
    open_problem_dialog(item) {
      this.set_problem(item).then(() => {
        this.problem_dialog = true;
      });
    },
    decode_json(jsonObject) {
      let x = JSON.parse(jsonObject);
      return x;
    },

    async add_new_problem() {
      this.loading = true;
      if (this.form.problem_description == "") {
        return this.set_notification({
          message: "Please input a problem description first!",
          type: "error",
        });
      }
      this.form.customer_id = this.item.customer_id;
      this.form.unit_id = this.item.id;

      await this.add_problem(this.form)
        .then((response) => {
          if (this.item.problems == null) this.item.problems = [];
          this.item.problems.unshift(response.data);
          return this.set_notification({
            message: "You have successfully added a new problem!",
            type: "success",
          });
        })
        .catch((err) => {
          alert(err);
        });
      this.loading = false;
    },
  },
  created() {
    // this.get_prob();
  },
  components: {
    // JobOrderModal,
    ProblemLayoutModal: () => import("./problemLayoutModal.vue"),
  },
};
</script>

<style lang="scss" scoped></style>
