<template>
  <nav class="" v-if="user">
    <!-- nav drawer -->
    <v-navigation-drawer
      app
      v-model="drawer"
      src="@/assets/background1.jpg"
      width="320"
      class=""
    >
      <v-list>
        <!-- link 1 -->
        <template v-if="authenticated">
          <v-list-item>
            <v-list-item-avatar>
              <img src="@/assets/jmbcircle.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <!-- <template v-if="authenticated"> -->
              <v-list-item-title class="red--text accent-3 overline"
                >JMB<span class="blue--text">COMPUTERS</span></v-list-item-title
              >
              <v-list-item-subtitle class="white--text text--darken-1"
                >Fast and Reliable Services</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <div v-for="link in links" v-bind:key="link.icon">
            <v-list-item
              active-class="active"
              route
              :to="link.route"
              v-if="link.access_level <= user.access_level"
            >
              <v-list-item-icon>
                <v-icon class="white--text text--darken-2">{{
                  link.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text text--lighten-2">{{
                  link.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </template>
      </v-list>

      <template v-slot:append>
        <div class="pa-2 d-flex justify ml-3">
          <p class="caption white--text">
            <v-icon small color="white" class="pr-2">mdi-flash</v-icon>Powered
            by: <strong>JMBComputers</strong> | Version
            {{ packageJson.version }}
            <br />
            <span class="grey--text text--darken-1"
              ><v-icon color="white" small class="pr-2">mdi-gmail</v-icon>
              janmichaelbesinga873@gmail.com</span
            >
          </p>
          <!-- <v-btn block> Logout </v-btn> -->
        </div>
      </template>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import packageJson from "../../package.json";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["hide"],
  name: "Navbar",
  computed: {
    ...mapGetters({
      user: "auth/user",
      loading_out: "1",
    }),
  },
  data() {
    return {
      packageJson: packageJson,
      authenticated: true,
      drawer: true,
      links: [
        { icon: "mdi-home", name: "Dashboard", route: "/", access_level: "1" },
        {
          icon: "mdi-account-circle",
          name: "Accounts",
          route: "/accounts",
          access_level: "3",
        },
        {
          icon: "mdi-laptop",
          name: "Units",
          route: "/units",
          access_level: "3",
        },
        {
          icon: "mdi-cash-register",
          name: "Invoices",
          route: "/invoices",
          access_level: "3",
        },

        {
          icon: "mdi-network-pos",
          name: "Bills",
          route: "/bills",
          access_level: "3",
        },
        {
          icon: "mdi-draw",
          name: "Quotations",
          route: "/quotations",
          access_level: "3",
        },
        // {
        //   icon: "mdi-list-box-outline",
        //   name: "Chart Of Accounts",
        //   route: "/chart_of_accounts",
        //   access_level: "4",
        // },
        {
          icon: "mdi-star-box",
          name: "Products and Services",
          route: "/items",
          access_level: "4",
        },
        {
          icon: "mdi-draw-pen",
          name: "Accounting",
          route: "/journal_entries",
          access_level: "4",
        },
        {
          icon: "mdi-text-box-plus-outline",
          name: "Batch Imports",
          route: "/batch_import",
          access_level: "4",
        },
        {
          icon: "mdi-account-group",
          name: "Suppliers",
          route: "/suppliers",
          access_level: "4",
        },
        {
          icon: "mdi-domain",
          name: "Branches",
          route: "/branches",
          access_level: "4",
        },

        {
          icon: "mdi-account-hard-hat",
          name: "Employees and Wages",
          route: "/employees",
          access_level: "4",
        },
        {
          icon: "mdi-account-circle-outline",
          name: "User Accounts",
          route: "/user_accounts",
          access_level: "4",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      signOutActions: "auth/signOut",
    }),
    signOut() {
      this.loading_out = "2";
      this.signOutActions().then(() => {
        this.loading_out = "1";
        this.$router.push(`/signin`);
      });
    },
  },
};
</script>
<style scoped>
.active {
  background-image: #1565c0;
}
</style>
