var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","width":"1600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("All Items")]),_c('v-card-text',[_c('v-text-field',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.all_items,"headers":_vm.all_items_headers,"search":_vm.search},scopedSlots:_vm._u([{key:"item.item_name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.is_vat
                  ? 'green--text font-weight-bold'
                  : 'orange--text font-weight-bold'},[_vm._v(_vm._s(item.item_name))])]}},{key:"item.is_deleted",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"text":"","color":item.is_deleted === 1 ? 'red' : 'primary',"small":""}},[(item.is_deleted === 1)?_c('span',[_vm._v("Deleted")]):_c('span',[_vm._v("Active")])])]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.restore(item)}}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v("Restore")],1)]}}])})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }