<template>
  <div>
    <v-btn color="green" class="" icon x-large @click="dialog = true"
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >
    <v-dialog v-model="dialog" width="700">
      <v-card class="pa-4">
        <v-card-title> <h2>Add Unit</h2></v-card-title>
        <v-card-text>
          <v-text-field
            label="Search "
            v-model="customer_search"
          ></v-text-field>
          <p>Account Name:</p>
          <h2 class="green--text">
            {{ selected_account.customer_name }}
          </h2>

          <v-data-table
            :search="customer_search"
            :items="customers"
            :headers="customer_headers"
            :options="{ itemsPerPage: 3 }"
          >
            <template v-slot:item.action="{ item }">
              <v-btn small color="primary" @click="select_account(item)"
                >select</v-btn
              >
            </template>
          </v-data-table>

          <v-form ref="form">
            <v-row>
              <v-col cols="">
                <v-select
                  prepend-icon="mdi-laptop"
                  :items="unit_type"
                  label="Unit Type"
                  v-model="form.unit_type"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-select>
                <v-text-field
                  label="Unit Brand"
                  prepend-icon="mdi-apple"
                  v-model="form.unit_brand"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  prepend-icon="mdi-ab-testing"
                  label="Unit Model"
                  v-model="form.unit_model"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-qrcode"
                  label="Serial Number"
                  v-model="form.serial_no"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field
              ></v-col>
            </v-row>
            <div class="d-flex">
              <p class="mr-2 mb-n5">Date Received</p>
              <input type="date" v-model="form.date_received" />
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="addUnit" class="" :loading="loading">Add</v-btn>
          <v-btn @click="dialog = false">Close</v-btn>

          <v-spacer></v-spacer>
          <p class="caption grey--text">addUnitModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  created() {
    var date = moment().format("Y-MM-DD");
    this.form.date_received = date;
  },
  data() {
    return {
      customer_search: "",
      customer_headers: [
        { text: "Action", value: "action" },
        { text: "Account Name", value: "customer_name" },
        { text: "Date Created", value: "created_at" },
      ],
      dialog: false,
      loading: false,
      selected_account: {},
      unit_type: ["Laptop", "Printer", "Desktop PC", "Others"],
      form: {
        customer_id: null,
        unit_model: null,
        unit_brand: null,
        unit_type: null,
        serial_no: null,
        date_received: null,
        issued_warranty: [],
        includes: [],
        accessories: [],
      },
    };
  },
  computed: {
    ...mapGetters({ customers: "customer/customers" }),
  },
  methods: {
    select_account(item) {
      this.selected_account = item;
      this.form.customer_id = item.id;
      return item;
    },
    close() {
      this.$emit("close");
    },
    ...mapActions({
      add_unit: "unit/add_unit",
      set_notification: "notification/set_notification",
    }),
    async addUnit() {
      // check if empty
      if (
        !this.form.customer_id ||
        !this.form.unit_model ||
        !this.form.unit_brand ||
        !this.form.unit_type ||
        !this.form.serial_no
      )
        return alert("please check all fields");
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        this.loading = true;
        await this.add_unit(this.form).then(() => {
          this.loading = false;
        });

        return this.set_notification({
          message: "You have successfully inserted a new unit!",
          type: "success",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
