<template>
  <v-container class="mt-16">
    <div>
      <v-card flat>
        <v-card-title class="d-flex">
          <h2>Bills</h2>

          <v-spacer></v-spacer>
          <add-invoice-card :is_invoice="0" />
          <v-btn
            color=""
            class=""
            icon
            x-large
            @click="refresh"
            :loading="loading"
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
        </v-card-title>

        <v-card-text>
          <div>
            <v-text-field
              label="Search "
              prepend-icon="mdi-magnify"
              v-model="search"
            ></v-text-field>
            <v-data-table
              :items="all_bills"
              :headers="headers"
              :search="search"
              :loading="loading"
            >
              <template v-slot:item.action="{ item }">
                <div class="d-flex">
                  <v-btn
                    icon
                    @click="enablePayablesModal(item)"
                    color="primary"
                    class=""
                    ><v-icon class="">mdi-logout-variant</v-icon>
                  </v-btn>
                  <v-btn @click="open_payment(item)" color="yellow" icon
                    ><v-icon>mdi-cash-multiple</v-icon></v-btn
                  >
                  <!-- <confirm-invoice-print-modal :item="item" /> -->
                  <v-btn
                    v-if="user.access_level >= 4"
                    icon
                    @click="del_bill(item)"
                    color="red"
                    class="mr-2"
                    :loading="loading"
                    ><v-icon class="mr-2">mdi-delete</v-icon></v-btn
                  >
                  <v-btn
                    @click="print_modal(item)"
                    small
                    icon
                    color="success"
                    class="mt-1"
                    ><v-icon>mdi-printer</v-icon></v-btn
                  >
                </div>
              </template>
              <template v-slot:item.invoice_status="{ item }">
                <span
                  :class="
                    item.invoice_status == 'Paid' ? 'green--text' : 'red--text'
                  "
                  >{{ item.invoice_status }}</span
                >
              </template>
              <template v-slot:item.is_invoice="{}">
                <v-chip color="red" class="white--text">Bill</v-chip>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <!-- lazy loaded -->
    <add-payment
      v-if="enable_add_payment_modal"
      :invoice="selected_invoice"
      @close="enable_add_payment_modal = false"
    />
    <print-modal-card
      :item="selected_invoice"
      v-if="show_print_modal"
      @close="show_print_modal = false"
    />
    <!-- Lazy loaded -->
    <payables-modal
      v-if="enable_payables_modal"
      :invoice="selected_invoice"
      @close="enable_payables_modal = false"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddInvoiceCard from "../components/Invoice/addInvoiceCard.vue";

export default {
  components: {
    AddInvoiceCard,
    AddPayment: () => import("../components/Payment/addPaymentModal.vue"),
    payablesModal: () => import("@/components/Payment/payablesModal.vue"),
    PrintModalCard: () => import("../components/Invoice/printModalCard.vue"),
  },
  data() {
    return {
      enable_add_payment_modal: false,
      enable_payables_modal: false,
      ready: false,
      search: "",
      loading: false,
      headers: [
        { text: "Action", value: "action" },
        { text: "ID", value: "id" },
        { text: "Bill Code", value: "invoice_code" },
        { text: "Account Name", value: "customer.customer_name" },
        { text: "Bill Date", value: "invoice_date" },
        { text: "Bill Status", value: "invoice_status" },
        { text: "Balance", value: "balance" },
        { text: "Total Amount", value: "total_amount" },

        { text: "Type", value: "is_invoice" },
        { text: "Purpose", value: "purpose" },
      ],
      show_print_modal: false,
      selected_invoice: null,
    };
  },
  computed: {
    ...mapGetters({
      all_bills: "invoice/all_bills",
      user: "auth/user",
    }),
  },
  methods: {
    open_payment(item) {
      this.selected_invoice = item;
      this.enable_add_payment_modal = true;
    },
    enablePayablesModal(invoice) {
      this.selected_invoice = invoice;
      this.enable_payables_modal = true;
      console.log("triggered");
    },
    print_modal(item) {
      this.show_print_modal = true;
      this.selected_invoice = item;
    },
    async del_bill(item) {
      this.loading = true;
      let y = window.confirm("Are you sure you want to delete this bill?");
      if (y) {
        await this.delete_bill(item);
        this.loading = false;
        alert("Successfully deleted an bill");
      } else {
        this.loading = false;
      }
    },
    refresh() {
      this.loading = true;
      this.get_all_bills().then(() => {
        this.loading = false;
      });

      return;
    },
    ...mapActions({
      get_all_bills: "invoice/get_all_bills",
      delete_bill: "invoice/delete_invoice",
    }),
  },
  async created() {
    this.loading = true;
    if (this.all_bills.length <= 0) {
      await this.get_all_bills();
    }
    this.loading = false;
  },
};
</script>

<style></style>
