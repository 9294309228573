<template>
  <v-container class="mt-16">
    <div class="">
      <v-card flat>
        <v-card-title class="d-flex">
          <h2>Accounts</h2>
          <v-spacer></v-spacer>
          <AddCustomerModal v-if="user.access_level >= 3" />
          <v-btn
            color=""
            class=""
            icon
            x-large
            @click="refresh"
            :loading="loading"
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Search for something"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            placeholder="John Doe"
          ></v-text-field>
          <v-data-table
            :search="search"
            class="mt-5"
            :items="customers"
            :headers="headers"
            v-if="customers"
            :loading="loading"
            dense
          >
            <template v-slot:item.created_at="{ item }">
              {{ formatted_date(item.created_at) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <v-btn icon @click="openAccount(item)" color="primary"
                  ><v-icon>mdi-account</v-icon></v-btn
                >
                <EditCustomerModal
                  :customer="item"
                  v-if="user.access_level >= 4"
                />
                <v-btn
                  icon
                  color="red"
                  @click="del(item)"
                  :loading="loading"
                  v-if="user.access_level >= 4"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddCustomerModal from "../components/Customer/addCustomerModal.vue";
import EditCustomerModal from "../components/Customer/editCustomerModal.vue";
import moment from "moment";
export default {
  data() {
    return {
      ready: false,

      selectedItem: "",
      enableCustomerModal: false,
      search: "",
      loading: false,
      headers: [
        { text: "", value: "actions" },
        {
          text: "ID",
          value: "id",
        },

        { text: "Customer Name", value: "customer_name" },
        { text: "Contact Number", value: "customer_contact_no" },
        { text: "Other Contact Platform", value: "other_contact_platform" },
        { text: "Customer Municipality", value: "customer_municipality" },
        { text: "Customer Barangay", value: "customer_barangay" },
        { text: "Customer Purok", value: "customer_purok" },

        { text: "Date Inserted", value: "created_at" },
      ],
      //   ['customer_name', 'customer_contact_no',
      //  'other_contact_platform', 'customer_municipality',
      //  'customer_barangay', 'customer_purok', 'where_did_you_find_us']
    };
  },
  computed: {
    ...mapGetters({
      customers: "customer/customers",
      user: "auth/user",
    }),
  },
  methods: {
    refresh() {
      this.loading = true;
      this.get_customers().then(() => {
        this.loading = false;
      });
    },
    formatted_date(date) {
      let x = moment(date).format("LL");

      return x;
    },
    del(item) {
      this.loading = true;
      if (this.user.access_level < 4) {
        this.loading = false;
        return alert("You are not allowed to do this.");
      }
      let x = window.confirm("Are you sure you want to delete this customer?");

      if (x) {
        this.delete_customer(item).then(() => {
          alert("Successfully deleted a customer");
        });
      }
      this.loading = false;
    },
    ...mapActions({
      get_customers: "customer/get_customers",
      delete_customer: "customer/delete_customer",
    }),
    checkCustomers() {
      if (!this.customers) return false;
      return true;
    },
    openAccount(item) {
      this.$router.push("account_profile/" + item.id);
      this.enableCustomerModal = true;
      this.selectedItem = item;
    },
  },
  async created() {
    this.loading = true;
    if (this.customers.length == 0) {
      await this.get_customers();
    }
    this.loading = false;
  },
  components: {
    AddCustomerModal,
    // customerModal: () => import("@/components/Customer/customerModal.vue"),
    EditCustomerModal,
  },
};
</script>

<style lang="scss" scoped></style>
