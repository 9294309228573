<template>
  <div>
    <v-dialog v-model="dialog" width="950" persistent v-if="invoice">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col><h2>Invoice Card</h2></v-col>
            <v-col cols="3"
              ><p class="blue--text">
                Invoice Code: {{ invoice.invoice_code }}
              </p></v-col
            >
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- INVOICE ITEMS TABLE -->
          <div>
            <v-data-table
              :items="invoice.invoice_items"
              :headers="headers"
              dense
            >
              <template v-slot:item.item.warranty="{ item }">
                <span v-if="item.item.warranty">
                  {{ item.item.warranty.warranty_count }} -
                  {{ item.item.warranty.warranty_duration }}</span
                >
                <span v-else>No Warranty Stated</span>
              </template>
            </v-data-table>
          </div>

          <hr />

          <v-row class="mt-2">
            <v-col cols="3" class="">
              <h2 class="ml-3">Summary</h2>
              <edit-invoice-modal :invoice="invoice" class="mt-2" />
            </v-col>
            <v-col>
              <p>Amount: {{ invoice.amount }}</p>

              <p>Discount: {{ invoice.discount }}</p>
              <p>VAT: {{ invoice.vat }}</p>
            </v-col>
            <v-col>
              <p>Total Amount: {{ invoice.total_amount }}</p>
              <p>Balance: {{ invoice.balance }}</p>
              <p>
                Status:
                <span
                  :class="
                    invoice.invoice_status === 'Waiting for Payment'
                      ? 'orange--text font-weight-bold'
                      : invoice.invoice_status === 'With Balance'
                      ? 'orange--text font-weight-bold'
                      : 'green--text font-weight-bold'
                  "
                  >{{ invoice.invoice_status }}</span
                >
              </p>
            </v-col>
            <v-col>
              <p>Date: {{ invoice.created_at }}</p>
              <p>Purpose: {{ invoice.purpose }}</p>
              <p>Inserted by: {{ invoice.inserted_by }}</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <div class="mt-3">
            <h2>Payments</h2>
            <v-data-table :items="invoice.payments" :headers="payment_headers">
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="$emit('close')">Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">payablesModal.vue</p>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
// import addItemModal from "./addItemModal.vue";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import EditInvoiceModal from "../Invoice/editInvoiceModal.vue";
export default {
  created() {
    // console.log(this.invoice);
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  props: ["invoice"],
  methods: {
    ...mapActions({
      delete_invoice_item: "invoice/delete_invoice_item",
    }),
    del_item(item) {
      let x = window.confirm("Are you sure you want to delete this?");
      if (x) {
        this.loading = true;

        this.delete_invoice_item(item.id)
          .then(() => {
            this.loading = false;
            alert("Successfully deleted a payable!");
          })
          .catch((err) => {
            alert(err);
            this.loading = false;
          });
      }
    },
    del(item) {
      this.loading = true;
      let x = window.confirm("Are you sure you want to delete this payment?");
      if (x) {
        this.delete_payment(item.id).then(() => {
          alert("successfully deleted a payment!");
        });
      }
      this.loading = false;
    },
    async delete_payment(payment_id) {
      await axios
        .delete("/payment/" + payment_id + "/" + this.invoice.id)
        .then(() => {
          // remove from list
          let filtered = this.invoice.payments.filter((p) => {
            return p.id != payment_id;
          });
          this.invoice.payments = filtered;
        });
    },
  },

  data() {
    return {
      loading: false,
      dialog: true,
      payment_headers: [
        // { text: "", value: "action" },
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Amount Paid",
          value: "amount_paid",
        },
        { text: "Payment Method", value: "chart_of_account.account_title" },
        { text: "Payment Date", value: "payment_date" },
        { text: "Tax Code", value: "tax_code" },
        { text: "OR Number", value: "or_number" },
      ],
      headers: [
        // { text: "Action", value: "actions" },
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Description",
          value: "item.item_name",
        },
        {
          text: "Batch Import",
          value: "item.import_batch.batch_description",
        },
        {
          text: "Amount",
          value: "item.selling_price",
        },
        { text: "Quantity", value: "quantity" },
        {
          text: "Unit",
          value: "item.unit",
        },
        {
          text: "Total Amount",
          value: "amount",
        },
        { text: "Warranty", value: "item.warranty" },
        {
          text: "Account Title",
          value: "item.chart_of_account.account_title",
        },
      ],
    };
  },
  components: { EditInvoiceModal },
};
</script>

<style lang="scss" scoped></style>
