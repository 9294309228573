<template>
  <v-dialog v-model="dialog" persistent width="1300">
    <v-card>
      <v-card-title>Paid Invoices</v-card-title>
      <v-card-text>
        <v-text-field v-model="search"></v-text-field>
        <v-data-table
          :items="paid_invoices"
          :headers="headers"
          :search="search"
          :loading="loading"
        >
          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <v-btn
                icon
                @click="enablePayablesModal(item)"
                color="primary"
                class=""
                ><v-icon class="">mdi-logout-variant</v-icon>
              </v-btn>

              <v-btn
                @click="printModal(item)"
                small
                icon
                color="success"
                class="mt-1"
                ><v-icon>mdi-printer</v-icon></v-btn
              >

              <v-btn
                v-if="user.access_level >= 4"
                icon
                @click="del_invoice(item)"
                color="red"
                class="mr-1"
                :loading="loading"
                ><v-icon class="mr-2">mdi-delete</v-icon></v-btn
              >
            </div>
          </template>
          <template v-slot:item.invoice_status="{ item }">
            <span
              :class="
                item.invoice_status == 'Paid' ? 'green--text' : 'red--text'
              "
              >{{ item.invoice_status }}</span
            >
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$emit('close')"> Close</v-btn>
        <v-spacer></v-spacer>
        <p class="caption grey--text">paidInvoicesModal.vue</p>
      </v-card-actions>
    </v-card>
    <!-- Lazy loaded -->
    <payables-modal
      v-if="enable_payables_modal"
      :invoice="selected_invoice"
      @close="enable_payables_modal = false"
    />
    <print-modal-card
      v-if="show_print_modal"
      :item="selected_invoice"
      @close="show_print_modal = false"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import PrintModalCard from "./printModalCard.vue";

export default {
  components: {
    payablesModal: () => import("@/components/Payment/payablesModal.vue"),
    PrintModalCard: () => import("./printModalCard.vue"),
  },
  props: ["item"],
  data() {
    return {
      enable_payables_modal: false,
      selected_invoice: [],
      dialog: true,
      loading: false,
      headers: [
        { text: "Action", value: "action", width: "80" },
        { text: "ID", value: "id" },
        { text: "Invoice Code", value: "invoice_code" },
        { text: "Account Name", value: "customer.customer_name" },
        { text: "Invoice Date", value: "invoice_date" },
        { text: "Invoice Status", value: "invoice_status" },
        { text: "Balance", value: "balance" },
        { text: "VAT", value: "vat" },
        { text: "Total Amount", value: "total_amount" },

        { text: "Type", value: "is_invoice" },
        { text: "Purpose", value: "purpose" },
      ],
      show_print_modal: false,
      search: "",
    };
  },
  methods: {
    enablePayablesModal(invoice) {
      this.selected_invoice = invoice;
      this.enable_payables_modal = true;
      console.log("triggered");
    },
    ...mapActions({
      get_paid_invoices: "invoice/get_paid_invoices",
      delete_invoice: "invoice/delete_invoice",
    }),
    submit() {
      return;
    },
    async del_invoice(item) {
      this.loading = true;
      let y = window.confirm("Are you sure you want to delete this invoice?");
      if (y) {
        await this.delete_invoice(item);
        this.loading = false;
        alert("Successfully deleted an invoice");
      } else {
        this.loading = false;
      }
    },
    printModal(item) {
      this.selected_invoice = item;
      this.show_print_modal = true;
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      paid_invoices: "invoice/paid_invoices",
      default_server_url: "auth/default_server_url",
    }),
  },
  created() {
    if (this.paid_invoices.length <= 0) {
      this.loading = true;
      this.get_paid_invoices().then(() => {
        this.loading = false;
      });
    }
  },
};
</script>

<style lang="scss" scoped></style>
