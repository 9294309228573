var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-16"},[_c('div',{staticClass:"mb-2 d-flex"},[_c('p',{staticClass:"subtitle-2"},[_vm._v("Actions:")]),_c('print-batch-item-modal'),_c('returned-items-modal',{staticClass:"ml-2"}),_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){_vm.enable_all_item_modal = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-box")]),_vm._v("All Items List")],1),_c('item-types-modal',{staticClass:"mr-2"}),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"yellow"},on:{"click":_vm.print_inventory}},[_vm._v("Print Inventory")]),_c('item-transfer-modal')],1),_c('v-divider'),(_vm.ready)?_c('div',[_c('v-card',{staticClass:"pl-5 pr-5",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex"},[_c('h2',{staticClass:"text-uppercase"},[_vm._v("Products and Services")]),_c('v-spacer'),_c('AddItemModal'),_c('v-btn',{attrs:{"color":"","icon":"","x-large":"","loading":_vm.loading},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card-text',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Search Item"},model:{value:(_vm.item_search),callback:function ($$v) {_vm.item_search=$$v},expression:"item_search"}}),_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.item_headers,"search":_vm.item_search},scopedSlots:_vm._u([{key:"item.item_name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.is_vat
                  ? 'green--text font-weight-bold'
                  : 'orange--text font-weight-bold'},[_vm._v(_vm._s(item.item_name))])]}},{key:"item.warranty",fn:function(ref){
                  var item = ref.item;
return [(item.warranty)?_c('span',[_vm._v(_vm._s(item.warranty.warranty_count + " " + item.warranty.warranty_duration))]):_vm._e()]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.open_item_profile(item)}}},[_c('v-icon',[_vm._v("mdi-list-box")])],1),_c('v-btn',{attrs:{"icon":"","color":"yellow"},on:{"click":function($event){return _vm.edit_item(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red","loading":_vm.loading},on:{"click":function($event){return _vm.confirm_delete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,false,415331747)})],1)],1),_c('v-divider')],1):_c('v-row',{staticClass:"d-flex justify-center"},[_c('h2',{staticClass:"text-center mt-10 mb-10"},[_vm._v("Data Table is loading...")])]),(_vm.item_profile_modal)?_c('item-profile-modal',{attrs:{"item":_vm.selected_item},on:{"close":function($event){_vm.item_profile_modal = false}}}):_vm._e(),(_vm.edit_item_modal)?_c('edit-item-modal',{attrs:{"item":_vm.selected_item},on:{"close":function($event){_vm.edit_item_modal = false}}}):_vm._e(),(_vm.enable_all_item_modal)?_c('all-item-modal',{on:{"close":function($event){_vm.enable_all_item_modal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }