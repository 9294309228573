<template>
  <div>
    <v-btn @click="dialog = true" icon color="success"
      ><v-icon>mdi-check-circle</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Update Item Order</v-card-title>
        <v-card-text>
          <p>Date Arrived</p>
          <input
            type="date"
            v-model="item.date_arrived"
            @change="change_status"
          />
          <v-text-field label="Remarks" v-model="item.remarks"></v-text-field>
          <v-select
            label="Order Status"
            :items="[
              'pending',
              'completed',
              'returned',
              're-ordered',
              'cancelled',
            ]"
            v-model="item.order_status"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      edit_item_order: "item/edit_item_order",
    }),
    submit() {
      this.loading = true;
      let x = window.confirm("Are you sure you want to update this row?");
      if (x) {
        this.edit_item_order(this.item).then(() => {
          alert("You have successfully updated this order");
          return;
        });
      } else {
        this.loading = false;
        return;
      }
      this.loading = false;
    },
    change_status() {
      this.item.order_status = "completed";
    },
  },
};
</script>

<style lang="scss" scoped></style>
